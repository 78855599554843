import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Clickup from "./pages/clickup/clickup";
import Mailer from "./pages/devTools/mailer/mailer";
import Feed from "./pages/sirenFeed/feed";
import Toolkit from "./pages/toolkit/toolkit";
import Response from "./pages/toolkit/response";
import Login from "./pages/login/login";
import Upload from "./pages/sirenFeed/upload/upload";
import Post from "./pages/sirenFeed/posts/posts";
import interactor from "./pages/interactor/interactor";
import ServerSetup from "./pages/devTools/serverSetup/serverSetup";
import Ssl from "./pages/devTools/ssl/ssl";
import Mailchimp from "./pages/devTools/mailchimp/mailchimp";
import Creds from "./pages/creds/creds";

// Define route type with component as React.ComponentType
interface RouteConfig {
    path: string;
    component: React.ComponentType;
}

const App: React.FC = () => {
    const [isLoggedIn, setLogIn] = useState<boolean>(false);

    const handleLogin = () => {
        localStorage.setItem("token", "true");
        setLogIn(true);
    };

    const routes: RouteConfig[] = [
        { path: "/", component: Feed },
        { path: "/clickup", component: Clickup },
        { path: "/feed", component: Feed },
        { path: "/feed/upload", component: Upload },
        { path: "/feed/:postId", component: Post },
        // Ai Tools
        { path: "/toolkit", component: Toolkit },
        { path: "/toolkit/result", component: Response },
        { path: "/gpt-ui", component: interactor },
        // Dev Tools
        { path: "/mailer", component: Mailer },
        { path: "/server-setup", component: ServerSetup },
        { path: "/ssl", component: Ssl },
        { path: "/mailchimp", component: Mailchimp },
        // Creds
        { path: "/creds", component: Creds },
        // 404
        { path: "/404", component: Feed },
    ];

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setLogIn(true);
        }
    }, []);

    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    {routes.map((route, index) => {
                        const Component = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    isLoggedIn ? (
                                        <Component />
                                    ) : (
                                        <Login onLogin={handleLogin} />
                                    )
                                }
                            />
                        );
                    })}
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
};

export default App;
