

const Creds = () => {

    return (
        <>
            <h1>
                Creds
            </h1>
        </>

    )

}


export default Creds;