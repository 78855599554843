

const Mailchimp = () => {


    return (
        <div className='hero'>

            <h1>Mailchimp</h1>
        </div>
    );
}


export default Mailchimp;